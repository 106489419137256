import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import SoftButton from "../../../components/SoftButton";

const slides = [
  {
    title: "Workplacer/Elevate",
    description: "Supporting you in every step of a workplace transformation from your initial vision to a detailed plan of your new office",
    details: [], // Empty details for hero slide
  },
  {
    title: "Steps",
    description: "Supporting you in every step of a workplace transformation",
    details: [
      {
        step: "1",
        title: "Manage Your Portfolio",
        description: "Oversee workplace strategies, office portfolios, and contracts with a structured approach.",
        image: "/static/images/slides/1.png"
      },
      {
        step: "2",
        title: "Discover Your Way of Working",
        description: "Discover data-driven and interactive insights on your Way of Working with Workplacer surveys",
        image: "/static/images/slides/2.png"
      },
      {
        step: "3",
        title: "Define Your Workplace Strategy",
        description: "Set clear goals, work models, and optimization strategies for your office environment.",
        image: "/static/images/slides/3.png"
      },
      {
        step: "4",
        title: "Design Your Physical Attributes",
        description: "Get an automated recommendation for your future workplace design and generate a Premises Program",
        image: "/static/images/slides/4.png"
      },
      {
        step: "5",
        title: "Choose Your Future Direction",
        description: "Compare your current setup with future needs using gap analysis.",
        image: "/static/images/slides/5.png"
      },
      {
        step: "6",
        title: "Decide Your Future Workplace Solution",
        description: "Advanced support in evaluating, selecting, and securing workplace solutions.",
        image: "/static/images/slides/6.png"
      }
    ],
  },
  {
    title: "Manage Your Portfolio",
    description: "Manage your overarching workplace strategies, office portfolio and contracts.",
    details: [
      "All governing workplace strategy data in one place for clear guidance",
      "Consistent format of key numbers for easy benchmarking",
      "Comprehensive overview and tracking of contracts",
      "Prospect potential of selected offices",
      "Growing evaluation base as projects are completed"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable overview of <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          key data as a basis for decisions and actions
        </Box>
      </Typography>
    )
  },
  {
    title: "Discover Your Way of Working",
    description: "Discover data-driven and interactive insights on your Way of Working with Workplacer surveys",
    details: [
      "Team Lead Survey for the managerial perspective",
      "Employee Survey for employee preferences",
      "Business specific needs",
      "Instant access to results and insights",
      "Benchmark with other organizations",
      "AI compiled summaries and recommendations"
    ],
    deliverable: (
      <Typography variant="body1">
        Sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          survey results, insights & analyzes
        </Box>
      </Typography>
    )
  },
  {
    title: "Define Your Workplace Strategy",
    description: "Define your Workplace Strategy with our supporting module.",
    details: [
      "Guidance and recommendations for your strategic direction",
      "Align local strategies with overarching workplace strategies",
      "Set clear goals, objectives and KPIs",
      "Calculate organizational development",
      "Define your Work model",
      "Set your dimensioning strategy",
      "Optimize location decisions"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Workplace Strategy
        </Box>
      </Typography>
    )
  },
  {
    title: "Design Your Physical Attributes",
    description: "Get an automated recommendation for your future workplace design and generate a Premises Program",
    details: [
      "Composition of work settings, support areas and business specific areas",
      "Guidance on choices",
      "Key figures for dimensioning",
      "Select your work settings configuration",
      "Create your design manual",
      "Scenario generation and comparison",
      "Blueprints of your design",
      "Detailed Premises Program based on your selection"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Premises Program
        </Box>
      </Typography>
    )
  },
  {
    title: "Choose Your Future Direction",
    description: "Compare your future needs with your current solution in a Gap analysis and consider your options.",
    details: [
      "Evaluate goal achievement and space utilization.",
      "Decide whether to reconfigure or relocate.",
      "Get AI-backed insights for optimal decisions.",
      "Understand capacity, functionality, and location factors."
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Gap analysis and direction statement
        </Box>
      </Typography>
    )
  },
  {
    title: "Decide Your Future Workplace Solution",
    description: "Get advanced support comparing options when searching the market and deciding on a new solution.",
    details: [
      "Explore and compare office space options.",
      "Generate inquiries and receive AI-backed evaluations.",
      "Review and compare lease offers effectively.",
      "Finalize agreements with a structured approach."
    ],
    deliverable: (
      <Typography variant="body1">
        The lease agreement of your future workplace
      </Typography>
    )
  },
  {
    title: "Ready to transform your workplace?",
    description: "Choose the solution that fits your needs",
    isPricingSlide: true,
  }
];

const backgroundColor = '#F0EEEB'; // Using the consistent light background color

const ElevateMobile = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{
      width: "100%",
      minHeight: "100vh",
      bgcolor: backgroundColor,
      pt: 8, // Account for navbar
      pb: 4,
      px: 2,
    }}>
      {slides.map((slide, i) => (
        <Box
          key={i}
          sx={{
            width: "100%",
            mb: 6, // Add spacing between sections
            scrollSnapAlign: 'start', // Smooth scrolling between sections
          }}
        >
          {i === 0 ? (
            // Hero Section
            <Box sx={{ mb: 4, height: '80vh', alignItems: 'center' }}>
              <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    color: 'primary.main'
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    color: 'text.secondary'
                  }}
                >
                  {slide.description}
                </Typography>
              </Stack>
            </Box>
          ) : i === 1 ? (
            // Steps Section
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h4"
                sx={{
                  mb: 3,
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                {slide.title}
              </Typography>
              
              {slide.details.map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 4,
                    p: 3,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}>
                    <Box
                      component="img"
                      src={step.image}
                      alt={step.title}
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 1,
                        mr: 2,
                        objectFit: 'cover'
                      }}
                    />
                    <Typography
                      variant="h5"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                      }}
                    >
                      <Box component="span" sx={{ opacity: 0.5, mr: 1 }}>
                        {step.step}
                      </Box>
                      {step.title}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '1rem',
                      lineHeight: 1.6,
                      pl: '76px', // Align with title (60px image + 16px margin)
                    }}
                  >
                    {step.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : slide.isPricingSlide ? (
            // Pricing CTA Section
            <Box sx={{ mb: 4, minHeight: '50vh', display: 'flex', alignItems: 'center' }}>
              <Stack sx={{ width: '100%', alignItems: 'center', spacing: 3 }}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    color: 'primary.main',
                    textAlign: 'center'
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 4,
                    color: 'text.secondary',
                    textAlign: 'center'
                  }}
                >
                  {slide.description}
                </Typography>
                <SoftButton
                  variant="gradient"
                  color="primary"
                  size="large"
                  onClick={() => navigate('/pricing')}
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: '1.1rem',
                    textTransform: 'none',
                    borderRadius: 2,
                  }}
                >
                  View Pricing
                  <ArrowForward sx={{ ml: 1 }} />
                </SoftButton>
              </Stack>
            </Box>
          ) : (
            // Detail Sections
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h4"
                sx={{
                  mb: 3,
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                {slide.title}
              </Typography>
              
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '1rem',
                  mb: 3,
                  lineHeight: 1.6,
                }}
              >
                {slide.description}
              </Typography>

              {slide.details && slide.details.length > 0 && (
                <Box sx={{ mb: 4 }}>
                  {slide.details.map((detail, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: 'text.secondary',
                        fontSize: '1rem',
                        mb: 2,
                        lineHeight: 1.6,
                        pl: 3,
                        position: 'relative',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          top: '0.5em',
                          width: 6,
                          height: 6,
                          borderRadius: '50%',
                          bgcolor: 'primary.main',
                        }
                      }}
                    >
                      {detail}
                    </Typography>
                  ))}
                </Box>
              )}

              {slide.deliverable && (
                <Box
                  sx={{
                    mt: 3,
                    p: 3,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    boxShadow: 1
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      mb: 1
                    }}
                  >
                    Deliverable
                  </Typography>
                  {slide.deliverable}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ElevateMobile;