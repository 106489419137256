import { useMediaQuery } from '@mui/material';
import MainNavbar from '../../../layouts/main/MainNavbar.js';
import ExpressContent from './ExpressContent.js';
import ExpressMobile from './ExpressMobile.js';


// ----------------------------------------------------------------------


export default function Express() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <>
      <MainNavbar />
      {isMobile ? <ExpressMobile /> : <ExpressContent standalone={true} />}
    </>
  );
}
