import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from 'notistack';
import 'react-internet-meter/dist/index.css';
import { Fab } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState } from 'react';
import { PopupModal } from "react-calendly";
import theme from "./assets/theme";
import { ProgressBarStyle } from './components/LoadingScreen';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import GlobalStyles from './theme/globalStyles';

// ----------------------------------------------------------------------

function App() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        <GlobalStyles />
        <ProgressBarStyle />
        <ScrollToTop />
        <SnackbarProvider />
        <Router />
        
        <PopupModal
          url="https://calendly.com/d/cqks-t59-3kv/workplacer-demo"
          pageSettings={{
            primaryColor: '5E064F',
            textColor: '7f7f7f'
          }}
          onModalClose={handleClose}
          open={open}
          rootElement={document.getElementById("root")}
        />
        
        <Fab
          variant="extended"
          color="primary"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
          }}
          onClick={() => setOpen(true)}
        >
          <CalendarMonthIcon sx={{ mr: 1 }} />
          Book a demo
        </Fab>
      </>
    </ThemeProvider>
  );
}

export default App;
