import { useMediaQuery } from '@mui/material';
import MainNavbar from '../../../layouts/main/MainNavbar.js';
import ElevateContent from './ElevateContent.js';
import ElevateMobile from './ElevateMobile.js';

// ----------------------------------------------------------------------

export default function Elevate() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <>
      <MainNavbar />
      {isMobile ? <ElevateMobile /> : <ElevateContent standalone={true} />}
    </>
  );
}
