import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, Link } from 'react-router-dom';
import brandwidth from "../../assets/images/logowidth.png";
import { MHidden } from '../../components/@material-extend';
import SettingFullscreenMenu from '../../components/settings/SettingFullscreenMenu';
import SoftBox from '../../components/SoftBox';
import useOffSetTop from '../../hooks/useOffSetTop';
import navConfig from './MenuConfig';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  //backgroundColor: 'rgba(0, 0, 0, 1)',
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('sm')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.shadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  // Don't render the navbar if we're on home page and haven't scrolled
  /* if (isHome && !isOffset) {
    return null;
  } */

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'background.default' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            /* bgcolor: 'background.default', */
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: { xs: 0, md: 0 },
            '&.MuiContainer-root': {
              paddingLeft: '0 !important',
              paddingRight: '0 !important'
            }
          }}
        >
          <Link to="/" style={{ textDecoration: 'none' }}>
            <SoftBox component="img" src={brandwidth} alt="Workplacer.ai Logo" width={200} />
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          {/* Desktop Menu - Show above 960px */}
          <MHidden width="lgDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {/* Tablet/Mobile Menu - Show below 960px */}
          <MHidden width="lgUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
