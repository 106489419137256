import { Add, ArrowDownward, ArrowUpward, JoinLeft } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const slides = [
  {
    title: "Elevating workplace transformation",
    description: "Creating attractive, supportive and right-sized workplaces",
    details: [], // Empty details for hero slide
  },
  {
    title: "Attract, support and right-size",
    description: "Attracting talent and engaging employees requires extraordinary workplaces, with fit for purpose environments and tools to support our work. All while maximizing cost efficiency and ensuring sustainable management. Creating extraordinary workplaces can be complex, costly and time-consuming. Workplacer elevates the transformation process, helping you create attractive, efficient, and right-sized workplaces.",
    details: [], // Removed bullet points to keep paragraph format
  },
  {
    title: "Harness the power of automation and AI",
    description: "Workplace transformation today often consist of many manual steps including data collection, analyzing and calculating. By adding digital intelligence to your transformation process you can accelerate decision-making, reduce costs, and ensure better-informed outcomes.",
    details: [], // Removed bullet points to keep paragraph format
  },
  {
    title: "Innovative software",
    description: "Workplacer's innovative software: Supports the management of your overarching workplace strategies and portfolio. Guides transformation projects for specific office locations.",
    details: [], // Removed bullet points to keep paragraph format
  },
  {
    title: "Access to transformation experts",
    description: "If you need expert guidance, Workplacer offers support at key stages of your journey, seamlessly integrated with our software.",
    details: [], // Removed bullet points to keep paragraph format
  },/* 
  {
    title: "What sets us apart",
    description: "Workplacer is revolutionizing the Workplace Advisory industry by blending digital intelligence with personalized expertise.",
    details: [
      "We digitize traditionally manual consulting processes, accelerating decision-making and reducing costs",
      "Our advisory resources include highly skilled and experienced Workplace Strategists",
      "By integrating external consulting expertise with your internal resources, we enhance your in-house capabilities",
      "Our software helps you evolve into a more self-sufficient organization with lasting workplace competencies",
      "The combination of Workplacer software and advisory drives structured, outcome-driven change"
    ],
  } */
];

const backgroundColor = '#F0EEEB'; // Using the consistent light background color

const WhyWorkplacerContent = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [activeDetail, setActiveDetail] = useState(0);
  const [isDetailTransitioning, setIsDetailTransitioning] = useState(false);
  const rotationInterval = 4000; // Increased to 5 seconds for better readability

  useEffect(() => {
    if (!slides[activeSlide]?.details?.length) return;

    const timer = setInterval(() => {
      setIsDetailTransitioning(true);
      setTimeout(() => {
        setActiveDetail((prev) =>
          (prev + 1) % slides[activeSlide].details.length
        );
        setIsDetailTransitioning(false);
      }, 500); // Half of transition duration
    }, rotationInterval);

    return () => clearInterval(timer);
  }, [activeSlide, rotationInterval]);

  const handleWheel = (e) => {
    e.preventDefault(); // Prevent default scroll behavior

    if (isTransitioning) return;

    const delta = e.deltaY;
    const threshold = 10; // Minimum scroll amount to trigger transition

    if (Math.abs(delta) < threshold) return;

    if (delta > 0 && activeSlide < slides.length - 1) {
      setIsTransitioning(true);
      setActiveSlide(prev => prev + 1);
      setTimeout(() => setIsTransitioning(false), 1000); // Increased cooldown period
    } else if (delta < 0 && activeSlide > 0) {
      setIsTransitioning(true);
      setActiveSlide(prev => prev - 1);
      setTimeout(() => setIsTransitioning(false), 1000);
    }
  };

  return (
    <Box sx={{
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      bgcolor: backgroundColor, // Use single background color instead of getBackgroundColor(activeSlide)
      display: 'flex',
    }}>
      {/* Up Arrow */}
      <IconButton
        onClick={() => !isTransitioning && activeSlide > 0 && setActiveSlide(prev => prev - 1)}
        sx={{
          position: 'fixed',
          top: { xs: 80, md: 100 }, // Adjusted for navbar height
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10,
          backgroundColor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            backgroundColor: 'background.paper',
            opacity: 0.9,
          },
          display: activeSlide === 0 ? 'none' : 'flex', // Hide instead of disable
        }}
      >
        <ArrowUpward sx={{ color: 'primary.main' }} />
      </IconButton>

      {/* Down Arrow */}
      <IconButton
        onClick={() => !isTransitioning && activeSlide < slides.length - 1 && setActiveSlide(prev => prev + 1)}
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10,
          backgroundColor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            backgroundColor: 'background.paper',
            opacity: 0.9,
          },
          display: activeSlide === slides.length - 1 ? 'none' : 'flex', // Hide instead of disable
        }}
      >
        <ArrowDownward sx={{ color: 'primary.main' }} />
      </IconButton>

      {/* Vertical Step Progress */}
      <Box sx={{
        position: 'fixed',
        left: 60,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        display: { xs: 'none', md: 'block' },
        width: 200, // Fixed width for step menu
      }}>
        {/* Intro bullet step */}
        <Box
          onClick={() => !isTransitioning && setActiveSlide(0)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            cursor: 'pointer',
            opacity: activeSlide === 0 ? 1 : 0.5,
            transition: 'all 0.3s ease',
            '&:hover': {
              opacity: 0.8,
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: activeSlide === 0 ? 'primary.main' : 'text.secondary',
              fontWeight: 600,
              fontSize: '0.875rem',
              width: '20px',
              textAlign: 'right',
              mr: 1,
              transition: 'color 0.3s ease',
            }}
          >
            •
          </Typography>

          <Box sx={{
            width: 2,
            height: 40,
            bgcolor: activeSlide === 0 ? 'primary.main' : 'rgba(222, 99, 161, 0.2)',
            mr: 2,
            transition: 'background-color 0.3s ease',
          }} />

          <Typography
            variant="body2"
            sx={{
              color: activeSlide === 0 ? 'primary.main' : 'text.secondary',
              fontWeight: activeSlide === 0 ? 600 : 400,
              fontSize: '0.875rem',
              maxWidth: 150,
              transition: 'color 0.3s ease',
            }}
          >
            Intro
          </Typography>
        </Box>

        {slides.map((slide, index) => (
          index !== 0 && (
            <Box
              key={index}
              onClick={() => !isTransitioning && setActiveSlide(index)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 3,
                cursor: 'pointer',
                opacity: index === activeSlide ? 1 : 0.5,
                transition: 'all 0.3s ease',
                '&:hover': {
                  opacity: 0.8,
                }
              }}
            >
              {/* Step Number */}
              <Typography
                variant="body2"
                sx={{
                  color: index === activeSlide ? 'primary.main' : 'text.secondary',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  width: '20px',
                  textAlign: 'right',
                  mr: 1,
                  transition: 'color 0.3s ease',
                }}
              >
                {index}
              </Typography>

              {/* Vertical Line */}
              <Box sx={{
                width: 2,
                height: 40,
                bgcolor: index === activeSlide ? 'primary.main' : 'rgba(222, 99, 161, 0.2)',
                mr: 2,
                transition: 'background-color 0.3s ease',
              }} />

              {/* Step Title */}
              <Typography
                variant="body2"
                sx={{
                  color: index === activeSlide ? 'primary.main' : 'text.secondary',
                  fontWeight: index === activeSlide ? 600 : 400,
                  fontSize: '0.875rem',
                  transition: 'color 0.3s ease',
                  maxWidth: 150,
                }}
              >
                {slide.title}
              </Typography>
            </Box>
          )
        ))}

        {/* How it works arrow step */}
        <Box
          onClick={() => window.location.href = '/how-it-works'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            cursor: 'pointer',
            opacity: 0.5,
            transition: 'all 0.3s ease',
            '&:hover': {
              opacity: 0.8,
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'primary.focus',
              fontWeight: 600,
              fontSize: '0.875rem',
              width: '20px',
              textAlign: 'right',
              mr: 1,
            }}
          >
            →
          </Typography>

          <Box sx={{
            width: 2,
            height: 40,
            bgcolor: 'primary.focus',
            mr: 2,
          }} />

          <Typography
            variant="body2"
            sx={{
              color: 'primary.focus',
              fontWeight: 400,
              fontSize: '0.875rem',
              maxWidth: 150,
            }}
          >
            How it works
          </Typography>
        </Box>
      </Box>

      {/* Main Content */}
      <Box
        onWheel={handleWheel}
        sx={{
          flex: 1,
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          pl: { md: '260px' }, // Add left padding to account for step menu
        }}
      >
        {slides.map((slide, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              width: "calc(100% - 260px)",
              height: "100%",
              display: "flex",
              p: { xs: 3, md: 6 },
              maxWidth: '1800px',
              margin: '0 auto',
              transition: "transform 0.7s cubic-bezier(0.4, 0, 0.2, 1)",
              transform: `translateY(${(i - activeSlide) * 100}%)`,
            }}
          >
            {/* Left Content Section */}
            <Box sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              pl: { xs: 4 },
              pr: { xs: 4},
            }}>
              {i === 0 ? (
                // Hero slide layout with Bento icon
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pl: { xs: 4 },
                  pr: { xs: 4},
                }}>
                  {/* Text Content */}
                  <Box sx={{
                    flex: 1,
                    maxWidth: '60%',
                  }}>
                    <Typography
                      variant="h2"
                      sx={{
                        mb: 4,
                        fontWeight: 'bold',
                        color: 'primary.main'
                      }}
                    >
                      {slide.title}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        maxWidth: '800px',
                        color: 'text.secondary'
                      }}
                    >
                      {slide.description}
                    </Typography>
                  </Box>

                  {/* Bento Icon Section */}
                  <Box sx={{
                    flex: 1,
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <JoinLeft sx={{
                      width: 200,
                      height: 200,
                      color: 'primary.main',
                      opacity: 0.9,
                    }} />
                  </Box>
                </Box>
              ) : (
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: 'primary.main'
                    }}
                  >
                    {slide.title}
                  </Typography>

                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: { xs: '0.9rem', md: '1rem', xl: '1.1rem' },
                      maxWidth: '800px',
                      lineHeight: 1.6,
                      '& span': {
                        color: 'primary.main',
                        fontWeight: 800,
                      }
                    }}
                  >
                    {slide.description}
                  </Typography>

                </>
              )}
            </Box>

            {/* Right Image Section - Only show for non-hero slides */}
            {1 === 0 && (
              <Box sx={{
                flex: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                pl: 4,
              }}>
                <Box
                  component="img"
                  src={`/static/images/slides/${i}.png`}
                  sx={{
                    maxWidth: '90%',
                    height: 'auto',
                    borderRadius: 2,
                    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WhyWorkplacerContent;
