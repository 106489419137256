import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import SoftButton from "../../../components/SoftButton";

const slides = [
  {
    title: "Workplacer/Elevate",
    description: "Supporting you in every step of a workplace transformation from your initial vision to a detailed plan of your new office",
    details: [], // Empty details for hero slide
  },
  {
    title: "Steps",
    description: "Supporting you in every step of a workplace transformation",
    details: [
      {
        step: "1",
        title: "Manage Your Portfolio",
        description: "Oversee workplace strategies, office portfolios, and contracts with a structured approach.",
        image: "/static/images/slides/1.png"
      },
      {
        step: "2",
        title: "Discover Your Way of Working",
        description: "Discover data-driven and interactive insights on your Way of Working with Workplacer surveys",
        image: "/static/images/slides/2.png"
      },
      {
        step: "3",
        title: "Define Your Workplace Strategy",
        description: "Set clear goals, work models, and optimization strategies for your office environment.",
        image: "/static/images/slides/3.png"
      },
      {
        step: "4",
        title: "Design Your Physical Attributes",
        description: "Get an automated recommendation for your future workplace design and generate a Premises Program",
        image: "/static/images/slides/4.png"
      },
      {
        step: "5",
        title: "Choose Your Future Direction",
        description: "Compare your current setup with future needs using gap analysis.",
        image: "/static/images/slides/5.png"
      },
      {
        step: "6",
        title: "Decide Your Future Workplace Solution",
        description: "Advanced support in evaluating, selecting, and securing workplace solutions.",
        image: "/static/images/slides/6.png"
      }
    ],
  },
  {
    title: "Manage Your Portfolio",
    description: "Manage your overarching workplace strategies, office portfolio and contracts.",
    details: [
      "All governing workplace strategy data in one place for clear guidance",
      "Consistent format of key numbers for easy benchmarking",
      "Comprehensive overview and tracking of contracts",
      "Prospect potential of selected offices",
      "Growing evaluation base as projects are completed"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable overview of <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          key data as a basis for decisions and actions
        </Box>
      </Typography>
    )
  },
  {
    title: "Discover Your Way of Working",
    description: "Discover data-driven and interactive insights on your Way of Working with Workplacer surveys",
    details: [
      "Team Lead Survey for the managerial perspective",
      "Employee Survey for employee preferences",
      "Business specific needs",
      "Instant access to results and insights",
      "Benchmark with other organizations",
      "AI compiled summaries and recommendations"
    ],
    deliverable: (
      <Typography variant="body1">
        Sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          survey results, insights & analyzes
        </Box>
      </Typography>
    )
  },
  {
    title: "Define Your Workplace Strategy",
    description: "Define your Workplace Strategy with our supporting module.",
    details: [
      "Guidance and recommendations for your strategic direction",
      "Align local strategies with overarching workplace strategies",
      "Set clear goals, objectives and KPIs",
      "Calculate organizational development",
      "Define your Work model",
      "Set your dimensioning strategy",
      "Optimize location decisions"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Workplace Strategy
        </Box>
      </Typography>
    )
  },
  {
    title: "Design Your Physical Attributes",
    description: "Get an automated recommendation for your future workplace design and generate a Premises Program",
    details: [
      "Composition of work settings, support areas and business specific areas",
      "Guidance on choices",
      "Key figures for dimensioning",
      "Select your work settings configuration",
      "Create your design manual",
      "Scenario generation and comparison",
      "Blueprints of your design",
      "Detailed Premises Program based on your selection"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Premises Program
        </Box>
      </Typography>
    )
  },
  {
    title: "Choose Your Future Direction",
    description: "Compare your future needs with your current solution in a Gap analysis and consider your options.",
    details: [
      "Evaluate goal achievement and space utilization.",
      "Decide whether to reconfigure or relocate.",
      "Get AI-backed insights for optimal decisions.",
      "Understand capacity, functionality, and location factors."
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Gap analysis and direction statement
        </Box>
      </Typography>
    )
  },
  {
    title: "Decide Your Future Workplace Solution",
    description: "Get advanced support comparing options when searching the market and deciding on a new solution.",
    details: [
      "Explore and compare office space options.",
      "Generate inquiries and receive AI-backed evaluations.",
      "Review and compare lease offers effectively.",
      "Finalize agreements with a structured approach."
    ],
    deliverable: (
      <Typography variant="body1">
        The lease agreement of your future workplace
      </Typography>
    )
  },
  {
    title: "Pricing & Plans",
    description: "Choose the solution that fits your needs",
    details: [],
    isPricingSlide: true,
  }
];

const backgroundColor = '#F0EEEB'; // Using the consistent light background color

const ElevateContent = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [activeDetail, setActiveDetail] = useState(0);
  const [isDetailTransitioning, setIsDetailTransitioning] = useState(false);
  const rotationInterval = 4000; // Increased to 5 seconds for better readability
  const [isAnnual, setIsAnnual] = useState(false);

  useEffect(() => {
    if (!slides[activeSlide]?.details?.length) return;

    const timer = setInterval(() => {
      setIsDetailTransitioning(true);
      setTimeout(() => {
        setActiveDetail((prev) =>
          (prev + 1) % slides[activeSlide].details.length
        );
        setIsDetailTransitioning(false);
      }, 400); // Half of transition duration
    }, rotationInterval);

    return () => clearInterval(timer);
  }, [activeSlide, rotationInterval]);

  const handleWheel = (e) => {
    e.preventDefault(); // Prevent default scroll behavior

    if (isTransitioning) return;

    const delta = e.deltaY;
    const threshold = 10; // Minimum scroll amount to trigger transition

    if (Math.abs(delta) < threshold) return;

    if (delta > 0 && activeSlide < slides.length - 1) {
      setIsTransitioning(true);
      setActiveSlide(prev => prev + 1);
      setTimeout(() => setIsTransitioning(false), 1000); // Increased cooldown period
    } else if (delta < 0 && activeSlide > 0) {
      setIsTransitioning(true);
      setActiveSlide(prev => prev - 1);
      setTimeout(() => setIsTransitioning(false), 1000);
    }
  };

  return (
    <Box sx={{
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      bgcolor: backgroundColor, // Use single background color instead of getBackgroundColor(activeSlide)
      display: 'flex',
    }}>
      {/* Up Arrow */}
      <IconButton
        onClick={() => !isTransitioning && activeSlide > 0 && setActiveSlide(prev => prev - 1)}
        sx={{
          position: 'fixed',
          top: { xs: 80, md: 100 }, // Adjusted for navbar height
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10,
          backgroundColor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            backgroundColor: 'background.paper',
            opacity: 0.9,
          },
          display: activeSlide === 0 ? 'none' : 'flex', // Hide instead of disable
        }}
      >
        <ArrowUpward sx={{ color: 'primary.main' }} />
      </IconButton>

      {/* Down Arrow */}
      <IconButton
        onClick={() => !isTransitioning && activeSlide < slides.length - 1 && setActiveSlide(prev => prev + 1)}
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10,
          backgroundColor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            backgroundColor: 'background.paper',
            opacity: 0.9,
          },
          display: activeSlide === slides.length - 1 ? 'none' : 'flex', // Hide instead of disable
        }}
      >
        <ArrowDownward sx={{ color: 'primary.main' }} />
      </IconButton>

      {/* Vertical Step Progress */}
      <Box sx={{
        position: 'fixed',
        left: 60,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        display: { xs: 'none', md: 'block' },
        width: 200,
      }}>
        {/* Intro bullet step */}
        <Box
          onClick={() => !isTransitioning && setActiveSlide(0)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            cursor: 'pointer',
            opacity: activeSlide === 0 ? 1 : 0.5,
            transition: 'all 0.3s ease',
            '&:hover': {
              opacity: 0.8,
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: activeSlide === 0 ? 'primary.main' : 'text.secondary',
              fontWeight: 600,
              fontSize: '0.875rem',
              width: '20px',
              textAlign: 'right',
              mr: 1,
              transition: 'color 0.3s ease',
            }}
          >
            •
          </Typography>

          <Box sx={{
            width: 2,
            height: 40,
            bgcolor: activeSlide === 0 ? 'primary.main' : 'rgba(222, 99, 161, 0.2)',
            mr: 2,
            transition: 'background-color 0.3s ease',
          }} />

          <Typography
            variant="body2"
            sx={{
              color: activeSlide === 0 ? 'primary.main' : 'text.secondary',
              fontWeight: activeSlide === 0 ? 600 : 400,
              fontSize: '0.875rem',
              maxWidth: 150,
              transition: 'color 0.3s ease',
            }}
          >
            Intro
          </Typography>
        </Box>

        {slides.map((slide, index) => (
          index !== 0 && index !== slides.length - 1 && ( // Skip first and last slide
            <Box
              key={index}
              onClick={() => !isTransitioning && setActiveSlide(index)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 3,
                cursor: 'pointer',
                opacity: index === activeSlide ? 1 : 0.5,
                transition: 'all 0.3s ease',
                '&:hover': {
                  opacity: 0.8,
                }
              }}
            >
              {/* Step Number */}
              <Typography
                variant="body2"
                sx={{
                  color: index === activeSlide ? 'primary.main' : 'text.secondary',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  width: '20px',
                  textAlign: 'right',
                  mr: 1,
                  transition: 'color 0.3s ease',
                }}
              >
                {index - 1}
              </Typography>

              {/* Vertical Line */}
              <Box sx={{
                width: 2,
                height: 40,
                bgcolor: index === activeSlide ? 'primary.main' : 'rgba(222, 99, 161, 0.2)',
                mr: 2,
                transition: 'background-color 0.3s ease',
              }} />

              {/* Step Title */}
              <Typography
                variant="body2"
                sx={{
                  color: index === activeSlide ? 'primary.main' : 'text.secondary',
                  fontWeight: index === activeSlide ? 600 : 400,
                  fontSize: '0.875rem',
                  transition: 'color 0.3s ease',
                  maxWidth: 150,
                }}
              >
                {slide.title}
              </Typography>
            </Box>
          )
        ))}

        {/* Try it out step */}
        <Box
          onClick={() => !isTransitioning && setActiveSlide(slides.length - 1)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            cursor: 'pointer',
            opacity: activeSlide === slides.length - 1 ? 1 : 0.5,
            transition: 'all 0.3s ease',
            '&:hover': {
              opacity: 0.8,
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'primary.focus',
              fontWeight: 600,
              fontSize: '0.875rem',
              width: '20px',
              textAlign: 'right',
              mr: 1,
            }}
          >
            →
          </Typography>

          <Box sx={{
            width: 2,
            height: 40,
            bgcolor: activeSlide === slides.length - 1 ? 'primary.main' : 'primary.focus',
            mr: 2,
          }} />

          <Typography
            variant="body2"
            sx={{
              color: activeSlide === slides.length - 1 ? 'primary.main' : 'primary.focus',
              fontWeight: activeSlide === slides.length - 1 ? 600 : 400,
              fontSize: '0.875rem',
              maxWidth: 150,
            }}
          >
            Try it out
          </Typography>
        </Box>
      </Box>

      {/* Main Content */}
      <Box
        onWheel={handleWheel}
        sx={{
          flex: 1,
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          pl: { md: '260px' }, // Add left padding to account for step menu
        }}
      >
        {slides.map((slide, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              alignItems: 'center',
              width: "100%",
              height: "100%",
              display: "flex",
              p: { xs: 3, md: 6 },
              maxWidth: '1800px',
              margin: '0 auto',
              transition: "transform 0.7s cubic-bezier(0.4, 0, 0.2, 1)",
              transform: `translateY(${(i - activeSlide) * 100}%)`,
            }}
          >
            {slide.isPricingSlide ? (
              <Box sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 3,
                  justifyContent: 'center',
                  alignItems: 'stretch',
                  px: { xs: 2, md: 8 },
                  maxWidth: { xs: '100%', md: '500px', lg: '800px', xl: '1000px' },
                  mx: 'auto',
                }}>
                  {/* Software License Card */}
                  <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    p: 2.5,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    maxWidth: 400,
                    minHeight: 350,
                  }}>
                    <Box sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      gap: 2,
                    }}>
                      <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                        Software License
                      </Typography>

                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        €{isAnnual ? '9,590' : '150'}<Typography component="span" variant="body1">/{isAnnual ? 'year' : 'employee in project'}</Typography>
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'secondary.main' }}>
                        Contact us for a Workplacer subscription
                      </Typography>

                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Access to Workplacer/Elevate - including all features of the Workplacer software. Pricing is per initiated project.
                      </Typography>
                    </Box>

                    <SoftButton
                      variant="gradient"
                      fullWidth
                      color="primary"
                      href="https://app.workplacer.ai"
                      size="large"
                    >
                      Try for free
                    </SoftButton>
                  </Box>

                  {/* Integrated Service Card */}
                  <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'primary.main',
                    color: '#ffffff',
                    borderRadius: 2,
                    p: 2.5,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    maxWidth: 400,
                    minHeight: 350,
                  }}>
                    <Box sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      gap: 2,
                    }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ffffff' }}>
                        Integrated Advisory
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#ffffff' }}>
                        Custom solution including:
                      </Typography>
                      <Box
                        component="ul"
                        sx={{
                          pl: 2,
                          '& li': {
                            color: '#ffffff',
                            mb: 0.5,
                            fontSize: '0.875rem',
                          }
                        }}
                      >
                        <li>Software license</li>
                        <li>Expert advisory</li>
                        <li>Implementation support</li>
                        <li>Custom adaptations</li>
                        <li>Training sessions</li>
                      </Box>
                    </Box>

                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      href="/contact"
                      sx={{
                        bgcolor: 'background.paper',
                        color: 'primary.main',
                        '&:hover': {
                          bgcolor: 'background.paper',
                          opacity: 0.9,
                        }
                      }}
                    >
                      Ask for a Quote
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                {i === 1 ? (
                  // Steps slide
                  <Box sx={{ alignItems: 'center', p: 2, width: 'calc(100% - 200px)' }}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 'bold',
                        color: 'primary.main',
                        mb: 2,
                      }}
                    >
                      {slide.title}
                    </Typography>

                    <Box sx={{ p: 4, display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {slide.details.map((step, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 3,
                            width: 'calc(50% - 2rem)',
                            '@media (max-width: 900px)': {
                              width: '100%'
                            }
                          }}
                        >
                          {/* Step Image */}
                          <Box
                            sx={{
                              width: 80,
                              height: 80,
                              flexShrink: 0,
                              bgcolor: 'grey.50',
                              borderRadius: 2,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              overflow: 'hidden'
                            }}
                          >
                            <Box
                              component="img"
                              src={step.image}
                              alt={step.title}
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }}
                            />
                          </Box>

                          {/* Step Content */}
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="h5"
                              sx={{
                                color: 'primary.main',
                                fontWeight: 'bold',
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                              }}
                            >
                              <Box
                                sx={{
                                  color: 'primary.main',
                                  opacity: 0.3,
                                  fontSize: '0.875rem',
                                  fontWeight: 'bold'
                                }}
                              >
                                {step.step}
                              </Box>
                              {step.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                color: 'text.secondary',
                                fontWeight: 400,
                                fontSize: '0.875rem',
                              }}
                            >
                              {step.description}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      pl: { xs: 4, },
                      pr: { xs: 4, },
                    }}>
                      {i === 0 ? (
                        // Hero slide layout with Bento icon
                        <Box sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          pl: { xs: 4 },
                          pr: { xs: 4 },
                        }}>
                          {/* Text Content */}
                          <Box sx={{
                            flex: 1,
                            maxWidth: '60%',
                          }}>
                            <Typography
                              variant="h2"
                              sx={{
                                mb: 2,
                                fontWeight: 'bold',
                                color: 'primary.main'
                              }}
                            >
                              {slide.title}
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{
                                maxWidth: '800px',
                                color: 'text.secondary'
                              }}
                            >
                              {slide.description}
                            </Typography>
                          </Box>

                          {/* Building Icon Section (replaced Bento) */}
                          <Box sx={{
                            flex: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                            <Icon
                              icon="bi:building-up"
                              color="#5E064F"
                              style={{
                                width: 200,
                                height: 200,
                                opacity: 0.9,
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Typography
                            variant="h2"
                            sx={{
                              mb: 2,
                              fontWeight: 'bold',
                              color: 'primary.main'
                            }}
                          >
                            {slide.title}
                          </Typography>

                          <Typography
                            sx={{
                              color: 'text.primary',
                              fontSize: { xs: '0.9rem', md: '1rem', xl: '1.1rem' },
                              lineHeight: 1.5,
                              mb: 4,
                              maxWidth: '600px',
                              '& span': {
                                color: 'primary.main',
                                fontWeight: 800,
                              }
                            }}
                          >
                            {slide.description}
                          </Typography>

                          {/* Replace the Animated Details Section with Grid Layout */}
                          {slide.details.length > 0 && (
                            <Box sx={{
                              display: 'grid',
                              gridTemplateColumns: {
                                xs: '1fr',
                                md: `repeat(${Math.ceil(slide.details.length / 4)}, 1fr)`,
                              },
                              gap: 4,
                              mb: 2,
                              maxWidth: '1000px'
                            }}>
                              {Array.from({ length: Math.ceil(slide.details.length / 4) }).map((_, colIndex) => (
                                <Box
                                  key={colIndex}
                                  component="ul"
                                  sx={{
                                    listStyle: 'none',
                                    p: 0,
                                    m: 0,
                                  }}
                                >
                                  {slide.details.slice(colIndex * 4, (colIndex + 1) * 4).map((detail, index) => (
                                    <Box
                                      key={index}
                                      component="li"
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        mb: 2.5,
                                        position: 'relative',
                                        pl: 3,
                                        '&::before': {
                                          content: '""',
                                          position: 'absolute',
                                          left: 0,
                                          top: '0.5em',
                                          width: 6,
                                          height: 6,
                                          borderRadius: '50%',
                                          bgcolor: 'primary.main',
                                        }
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: 'text.primary',
                                          fontSize: { xs: '0.9rem', md: '1rem', xl: '1.1rem' },
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        {detail}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              ))}
                            </Box>
                          )}

                          {/* Deliverable Section - Only show for non-hero slides */}
                          {i !== 0 && slide.deliverable && (
                            <Box
                              sx={{
                                mt: 2,
                                p: 2.5,
                                borderRadius: 2,
                                bgcolor: 'rgba(255,255,255,0.7)',
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  color: 'primary.main',
                                  fontWeight: 'bold',
                                  mb: 1,
                                }}
                              >
                                Deliverable
                              </Typography>
                              <Typography variant="body1">
                                {slide.deliverable}
                              </Typography>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>

                    {/* Right Image Section - Only show for non-hero slides */}
                    {i !== 0 && (
                      <Box sx={{
                        flex: 1,
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        pl: 4,
                      }}>
                        <Box
                          component="img"
                          src={`/static/images/slides/${i}.png`}
                          sx={{
                            maxWidth: '90%',
                            height: 'auto',
                            borderRadius: 2,
                            boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ElevateContent;