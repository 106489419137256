import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import book from '@iconify/icons-ic/book';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import cameraFill from '@iconify/icons-eva/camera-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';
import { Bolt } from '@mui/icons-material';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },/* 
  {
    title: 'This is Workplacer',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: PATH_PAGE.whyworkplacer
  }, */
  {
    title: 'How it works',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.howitworks
  },
  {
    title: 'Workplacer/Express',
    path: PATH_PAGE.express,
    icon: <Icon icon={Bolt} {...ICON_SIZE} />
  },
  {
    title: 'Workplacer/Elevate',
    path: PATH_PAGE.elevate,
    icon: <Icon icon={Bolt} {...ICON_SIZE} />
  },
  {
    title: 'Pricing',
    path: PATH_PAGE.pricing,
    icon: <Icon icon={Bolt} {...ICON_SIZE} />
  },
  {
    title: 'Contact',
    path: PATH_PAGE.contact,
    icon: <Icon icon={Bolt} {...ICON_SIZE} />
  }
];

export default menuConfig;
