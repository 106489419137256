import MainNavbar from '../../../layouts/main/MainNavbar.js';
import WhyWorkplacerContent from './WhyWorkplacerContent.js';


// ----------------------------------------------------------------------


export default function WhyWorkplacer() {

  return (
    <>
      <MainNavbar />
      <WhyWorkplacerContent />
    </>
  );
}
