import { ArrowDownward, ArrowUpward, ArrowForward } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SoftButton from "../../../components/SoftButton";
import { Icon } from "@iconify/react";
import { useNavigate } from 'react-router-dom';

const slides = [
  {
    title: "Workplacer/Express",
    description: "Quickly generate insights and an overall design of a right-sized and well supporting workplace based on your Way of Working",
    details: [], // Empty details for hero slide
  },
  {
    title: "Steps",
    description: "For those exploring new office layout possibilities and want quick recommendations",
    details: [
      {
        step: "1",
        title: "Discover Your Way of Working",
        description: "Use Workplacer surveys to gain data-driven insights about your activities, mobility, workplace preferences, and team relationships. Our AI technology compiles summaries and recommendations based on your unique needs.",
        image: "/static/images/slides/2.png"
      },
      {
        step: "2",
        title: "Define Your Workplace Dimensions",
        description: "Set your dimensioning strategy, plan for organizational growth, and select the right work settings. Get precise calculations for your workplace needs based on real data.",
        image: "/static/images/slides/3.png"
      },
      {
        step: "3",
        title: "Design Your Workplace Future",
        description: "Receive automated recommendations for your future workplace design, optimized for your specific requirements and ways of working.",
        image: "/static/images/slides/4.png"
      }
    ],
  },
  {
    title: "Manage Your Portfolio",
    description: "Manage your overarching workplace strategies, office portfolio and contracts.",
    details: [
      "All governing workplace strategy data in one place for clear guidance",
      "Consistent format of key numbers for easy benchmarking",
      "Comprehensive overview and tracking of contracts",
      "Prospect potential of selected offices",
      "Growing evaluation base as projects are completed"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable overview of <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          key data as a basis for decisions and actions
        </Box>
      </Typography>
    )
  },
  {
    title: "Discover Your Way of Working",
    description: "Discover data-driven and interactive insights on your Way of Working with Workplacer surveys",
    details: [
      "Team Lead Survey for the managerial perspective",
      "Employee Survey for employee preferences",
      "Business specific needs",
      "Instant access to results and insights",
      "Benchmark with other organizations",
      "AI compiled summaries and recommendations"
    ],
    deliverable: (
      <Typography variant="body1">
        Sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          survey results, insights & analyzes
        </Box>
      </Typography>
    )
  },
  {
    title: "Define Your Office Capacity",
    description: "Define the capacity you wish to dimension your future office for",
    details: [
      "Calculate your organizational development",
      "Define your dimensioning strategy"
    ],
    deliverable: (
      <Typography variant="body1">
        Calculated <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Workplace Dimensioning Numbers
        </Box>
      </Typography>
    ),
  },
  {
    title: "Design Your Physical Attributes",
    description: "Get an automated recommendation for your future workplace design",
    details: [
      "Composition of work settings, support areas and business specific areas",
      "Guidance on choices",
      "Key figures for dimensioning",
      "Select your work settings configuration",
      "Create your design manual",
      "Blueprints of your design",
      "Scenario generation and comparison"
    ],
    deliverable: (
      <Typography variant="body1">
        A sharable <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          blueprint
        </Box> of your office layout
      </Typography>
    )
  },
  {
    title: "Pricing & Plans",
    description: "Choose the solution that fits your needs",
    details: [],
    isPricingSlide: true, // Add this flag for special handling
  }
];

const backgroundColor = '#F0EEEB'; // Using the consistent light background color

const ExpressMobile = () => {
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [activeDetail, setActiveDetail] = useState(0);
  const [isDetailTransitioning, setIsDetailTransitioning] = useState(false);
  const rotationInterval = 4000; // Increased to 5 seconds for better readability
  const [isAnnual, setIsAnnual] = useState(false);

  useEffect(() => {
    if (!slides[activeSlide]?.details?.length) return;

    const timer = setInterval(() => {
      setIsDetailTransitioning(true);
      setTimeout(() => {
        setActiveDetail((prev) =>
          (prev + 1) % slides[activeSlide].details.length
        );
        setIsDetailTransitioning(false);
      }, 400); // Half of transition duration
    }, rotationInterval);

    return () => clearInterval(timer);
  }, [activeSlide, rotationInterval]);

  const handleWheel = (e) => {
    e.preventDefault(); // Prevent default scroll behavior

    if (isTransitioning) return;

    const delta = e.deltaY;
    const threshold = 10; // Minimum scroll amount to trigger transition

    if (Math.abs(delta) < threshold) return;

    if (delta > 0 && activeSlide < slides.length - 1) {
      setIsTransitioning(true);
      setActiveSlide(prev => prev + 1);
      setTimeout(() => setIsTransitioning(false), 1000); // Increased cooldown period
    } else if (delta < 0 && activeSlide > 0) {
      setIsTransitioning(true);
      setActiveSlide(prev => prev - 1);
      setTimeout(() => setIsTransitioning(false), 1000);
    }
  };

  return (
    <Box sx={{
      width: "100%",
      minHeight: "100vh",
      bgcolor: backgroundColor,
      pt: 8,
      pb: 4,
      px: 2,
    }}>
      {slides.map((slide, i) => (
        <Box
          key={i}
          sx={{
            width: "100%",
            mb: 6,
            scrollSnapAlign: 'start',
          }}
        >
          {i === 0 ? (
            // Hero Section - keep as is
            <Box sx={{ mb: 4, height: '80vh', alignItems: 'center' }}>
              <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    color: 'primary.main'
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    color: 'text.secondary'
                  }}
                >
                  {slide.description}
                </Typography>
              </Stack>
            </Box>
          ) : slide.isPricingSlide ? (
            // Pricing CTA Section with SoftButton
            <Box sx={{ mb: 4, minHeight: '50vh', display: 'flex', alignItems: 'center' }}>
              <Stack sx={{ width: '100%', alignItems: 'center', spacing: 3 }}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    color: 'primary.main',
                    textAlign: 'center'
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 4,
                    color: 'text.secondary',
                    textAlign: 'center'
                  }}
                >
                  {slide.description}
                </Typography>
                <SoftButton
                  variant="gradient"
                  color="primary"
                  size="large"
                  onClick={() => navigate('/pricing')}
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: '1.1rem',
                    textTransform: 'none',
                    borderRadius: 2,
                  }}
                >
                  View Pricing
                  <ArrowForward sx={{ ml: 1 }} />
                </SoftButton>
              </Stack>
            </Box>
          ) : i === 1 ? (
            // Steps Section
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h4"
                sx={{
                  mb: 3,
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                {slide.title}
              </Typography>
              
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '1rem',
                  mb: 3,
                  lineHeight: 1.6,
                }}
              >
                {slide.description}
              </Typography>

              {slide.details.map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 4,
                    p: 3,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}>
                    {step.image && (
                      <Box
                        component="img"
                        src={step.image}
                        alt={step.title}
                        sx={{
                          width: 60,
                          height: 60,
                          borderRadius: 1,
                          mr: 2,
                          objectFit: 'cover'
                        }}
                      />
                    )}
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                      }}
                    >
                      {step.title}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '1rem',
                      lineHeight: 1.6,
                    }}
                  >
                    {step.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            // Regular Content Sections
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h4"
                sx={{
                  mb: 3,
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                {slide.title}
              </Typography>
              
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '1rem',
                  mb: 3,
                  lineHeight: 1.6,
                }}
              >
                {slide.description}
              </Typography>

              {slide.details && (
                <Box sx={{ mb: 4 }}>
                  {slide.details.map((detail, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: 'text.secondary',
                        fontSize: '1rem',
                        mb: 2,
                        lineHeight: 1.6,
                        pl: 3,
                        position: 'relative',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          top: '0.5em',
                          width: 6,
                          height: 6,
                          borderRadius: '50%',
                          bgcolor: 'primary.main',
                        }
                      }}
                    >
                      {detail}
                    </Typography>
                  ))}
                </Box>
              )}

              {slide.deliverable && (
                <Box
                  sx={{
                    mt: 3,
                    p: 3,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    boxShadow: 1
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      mb: 1
                    }}
                  >
                    Deliverable
                  </Typography>
                  {slide.deliverable}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ExpressMobile;